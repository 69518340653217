import React from 'react';
// eslint-disable-next-line import/no-unresolved
import logo from '~/components/Layout/img/logo.png?width=470';

const Logo = () => (
  <figure className="icon">
    <img src={logo} width="360" height="112" alt="Stemma Guardia di Finanza" />
  </figure>
);

export default Logo;
